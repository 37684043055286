import { ETabIndexesOrderingLevels } from '@/types/types';
import { generateCardId } from '../helper_funcs/generateCardId';
import { atomThumbnailIconMarkup } from './thumbnail-icon';
import { buttonBackgroundCalculation } from '@/uikit/helper_funcs/buttonBackgroundCalculation';

export type TSTStateCalltoaction = {
	'text': string;
	'size'?: 'small' | 'medium';
	'styling'?: 'filled' | 'outline' | 'text';
	'icon-url'?: string;
	'icon-position'?: 'before' | 'after';
	'addClass'?: string;
	'addToId'?: string;
	'tabIndex'?: number | string;
	'override_button_normal_border'?: string;
	'override_button_normal_background'?: string;
	'override_button_normal_background_end'?: string;
	'override_button_normal_color'?: string;
	'override_button_normal_should_use_border'?: null | boolean;
	'override_button_hover_border'?: string;
	'override_button_hover_background'?: string;
	'override_button_hover_background_end'?: string;
	'override_button_hover_color'?: string;
	'override_button_hover_should_use_border'?: null | boolean;
	'override_button_active_color'?: string;
	'override_button_active_border'?: string;
	'override_button_active_background'?: string;
	'override_button_active_background_end'?: string;
	'override_button_active_should_use_border'?: null | boolean;
	'override_button_focus_color'?: string;
	'override_button_focus_border'?: string;
	'override_button_focus_background'?: string;
	'override_button_focus_background_end'?: string;
	'override_button_focus_outline'?: string;
	'override_button_focus_should_use_border'?: null | boolean;
	'override_is_hover_block'?: boolean;
};

export function atomCallToActionMarkup(state: TSTStateCalltoaction) {
	const text: string = state.text || '';
	const iconStr: string = state?.['icon-url'] || '';
	const iconPos: string = state?.['icon-position'] || '';
	const {
		override_button_normal_border,
		override_button_normal_background,
		override_button_normal_background_end,
		override_button_normal_color,
		override_button_normal_should_use_border,
		override_button_hover_border,
		override_button_hover_background,
		override_button_hover_background_end,
		override_button_hover_color,
		override_button_hover_should_use_border,
		override_button_active_color,
		override_button_active_border,
		override_button_active_background,
		override_button_active_background_end,
		override_button_active_should_use_border,
		override_button_focus_color,
		override_button_focus_border,
		override_button_focus_background,
		override_button_focus_background_end,
		override_button_focus_should_use_border,
		override_button_focus_outline,
	} = state || {};
	const id = `${generateCardId('calltoaction')}-${state?.addToId || ''}`;
	const isIconBefore: boolean = iconPos === 'before';
	const sizeModClass = state?.size ? `__${state.size}` : '__medium';
	const styleModClass = state?.styling ? `__${state.styling}` : '__filled';
	const horPaddingsModClass = state?.styling !== 'text' ? ' padding-24-left padding-24-right' : '';
	const passedClass = state?.addClass ? ` ${state?.addClass}` : '';
	const icon: string = !iconStr
		? ''
		: iconStr.match(/\/assets\/play\.svg$/gi)
			? `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="play-icon-svg">
                <g id="play">
                    <path id="Icon"
                    d="M5 4.98963C5 4.01847 5 3.53289 5.20249 3.26522C5.37889 3.03203 5.64852 2.88773 5.9404 2.8703C6.27544 2.8503 6.67946 3.11965 7.48752 3.65835L18.0031 10.6687C18.6708 11.1139 19.0046 11.3364 19.1209 11.6169C19.2227 11.8622 19.2227 12.1378 19.1209 12.3831C19.0046 12.6636 18.6708 12.8862 18.0031 13.3313L7.48752 20.3417C6.67946 20.8804 6.27544 21.1497 5.9404 21.1297C5.64852 21.1123 5.37889 20.968 5.20249 20.7348C5 20.4671 5 19.9815 5 19.0104V4.98963Z"
                    stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
            </svg>`
			: atomThumbnailIconMarkup(
					iconStr,
					`${text}-button-icon`,
					'',
					undefined,
					undefined,
					`margin-${isIconBefore ? 'right' : 'left'}: .5rem;`,
					true,
				);
	const addOverride = (cssVar: string, value: any) => (value ? `${cssVar}: ${value};` : '');
	const tabIndex = String(
		state?.tabIndex === undefined
			? ETabIndexesOrderingLevels.OTHER_AUTO
			: state.tabIndex || ETabIndexesOrderingLevels.OTHER_AUTO,
	);

	// Handle overrides with gradient
	const isHoverBlock = state?.override_is_hover_block;
	const isGradientButton = (cssBcgValue: string) => cssBcgValue?.match?.(/linear-gradient/gi);
	const finalBackgrounds = buttonBackgroundCalculation({
		override_button_normal_background,
		override_button_normal_background_end,
		override_button_hover_background,
		override_button_hover_background_end,
		override_button_active_background,
		override_button_active_background_end,
		override_button_focus_background,
		override_button_focus_background_end,
		isHoverBlock,
	});
	const { bcgNormal, bcgHover, bcgActive, bcgFocus } = finalBackgrounds;
	const useBorderNormal = override_button_normal_should_use_border !== false;
	const useBorderHover = override_button_hover_should_use_border !== false;
	const useBorderActive = override_button_active_should_use_border !== false;
	const useBorderFocus = override_button_focus_should_use_border !== false;
	const borderTransparentVal = 'transparent';
	const borderNormal = !useBorderNormal ? borderTransparentVal : override_button_normal_border;
	const borderHover =
		isHoverBlock && isGradientButton(bcgHover) && useBorderHover
			? (override_button_hover_border ?? '#FFF')
			: !useBorderHover
				? borderTransparentVal
				: override_button_hover_border;
	const borderActive =
		isHoverBlock && isGradientButton(bcgActive) && useBorderActive
			? (override_button_active_border ?? '#FFF')
			: !useBorderActive
				? borderTransparentVal
				: override_button_active_border;
	const borderFocus = !useBorderFocus ? borderTransparentVal : override_button_focus_border;
	const overrideClass = `${id}__overrides`;
	const overrideClassName = ` ${overrideClass}`;
	const overrideClassSelector = `.${overrideClass}`;

	return `
            <style>
				#${id}  {
					background: transparent !important;
					border-radius: 0 !important;
					border: 0 !important;
				}
				#${id}.__text,
				#${id}.__text .button-content {
					background: transparent !important;
					background-color: transparent !important;
					box-shadow: none !important;
					border: none !important;
				}
				#${id}.__text .button-content {
					outline: none !important;
				}
		
				#${id}.__filled:not(.__text),
				[data-theme="light"] #${id}:not(.__outline):not(.__text) .button-content {
					--button-cta-text-color-default: #FFFFFF;
					--button-cta-bcg-color-default: #000000;
					--button-cta-border-color-default: #000000;
				}
				#${id}.__outline:not(.__text),
				[data-theme="dark"] #${id}:not(.__filled):not(.__text) .button-content {
					--button-cta-text-color-default: #000000;
					--button-cta-bcg-color-default: #FFFFFF;
					--button-cta-border-color-default: #000000;
				}
				${id}.__filled:not(.__text) .button-content,
				[data-theme="light"] #${id}:hover:not(.__outline):not(.__text) .button-content,
				[data-theme="light"] [class*="ark-ui-block-card-hero-"] .card:hover #${id}:not(.__outline):not(.__text) .button-content {
					--button-cta-text-color-default: #FFFFFF;
					--button-cta-bcg-color-default: var(--color-gray-600);
					--button-cta-border-color-default: var(--color-gray-600);
				}
				${id}.__outline:not(.__text) .button-content,
				[data-theme="dark"] #${id}:hover:not(.__filled):not(.__text) .button-content,
				[data-theme="dark"] [class*="ark-ui-block-card"]:not(.ark-ui-block-card-promo) .card:hover #${id}:not(.__filled):not(.__text) .button-content {
					--button-cta-text-color-default: #000000;
					--button-cta-bcg-color-default: var(--color-gray-50);
					--button-cta-border-color-default: var(--color-black);
				}
				${id}.__filled:not(.__text):active .button-content,
				[data-theme="light"] #${id}:active:not(.__outline):not(.__text) .button-content,
				[data-theme="light"] [class*="ark-ui-block-card-hero-"] .card:active #${id}:not(.__outline):not(.__text) .button-content {
					--button-cta-text-color-default: #FFFFFF;
					--button-cta-bcg-color-default: var(--color-gray-500);
					--button-cta-border-color-default: var(--color-gray-500);
				}
				${id}.__outline .button-content,
				[data-theme="dark"] #${id}:active:not(.__filled):not(.__text) .button-content,
				[data-theme="dark"] [class*="ark-ui-block-card"] .card:active #${id}:not(.__filled):not(.__text) .button-content {
					--button-cta-text-color-default: #000000;
					--button-cta-bcg-color-default: var(--color-gray-100);
					--button-cta-border-color-default: var(--color-black);
				}
				[data-theme="light"] #${id}.__text .button-content  {
					--button-cta-text-color-default: var(--color-black);
				}
				[data-theme="dark"] #${id}.__text .button-content  {
					--button-cta-text-color-default: var(--color-white);
				}
                
                #${id} .button-content${overrideClassSelector} {
                    ${addOverride('--button-cta-text-color', override_button_normal_color || `var(--button-cta-text-color-default)`)}
                    ${addOverride('--button-cta-bcg-color', bcgNormal || `var(--button-cta-bcg-color-default)`)}
                    ${addOverride('--button-cta-border-color', borderNormal || `var(--button-cta-border-color-default)`)}
                    ${
						!useBorderNormal && isGradientButton(bcgNormal)
							? 'box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);'
							: `box-shadow: inset 0 0 0 2px var(--button-cta-border-color);`
					}
                    ${!isGradientButton(bcgNormal) ? 'box-shadow: none !important;' : 'border: none !important;'}
                }
                [class*="ark-ui-block-card-hero-"]:hover #${id}:not(.__text) .button-content${overrideClassSelector},
                [class*="ark-ui-block-card-standard-"]:hover #${id}:not(.__text) .button-content${overrideClassSelector},
                [class*="ark-ui-block-card-portrait-"]:hover #${id}:not(.__text) .button-content${overrideClassSelector},
                #${id}:hover:not(.__text) .button-content,
                #${id}:not(.__text) .button-content:hover {
                    ${addOverride('--button-cta-text-color', override_button_hover_color || `var(--button-cta-text-color-default)`)}
                    ${addOverride('--button-cta-bcg-color', bcgHover || `var(--button-cta-bcg-color-default)`)}
                    ${addOverride('--button-cta-border-color', borderHover || `var(--button-cta-border-color-default)`)}
                    ${
						!useBorderHover && !isHoverBlock && isGradientButton(bcgHover)
							? 'box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);'
							: `box-shadow: inset 0 0 0 2px var(--button-cta-border-color);`
					}
                }
                [class*="ark-ui-block-card-"]:active #${id}:not(.__text) .button-content${overrideClassSelector},
                .card:active #${id}:not(.__text) .button-content${overrideClassSelector},
                a:active #${id}:not(.__text) .button-content${overrideClassSelector},
                #${id}:active:not(.__text) .button-content${overrideClassSelector},
                #${id}:not(.__text) .button-content${overrideClassSelector}:active {
                    ${addOverride('--button-cta-text-color', override_button_active_color || `var(--button-cta-text-color-default)`)}
                    ${addOverride('--button-cta-border-color', borderActive || `var(--button-cta-border-color-default)`)}
                    ${addOverride('--button-cta-bcg-color', bcgActive || `var(--button-cta-bcg-color-default)`)}
                    ${
						!useBorderActive && !isHoverBlock && isGradientButton(bcgActive)
							? 'box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);'
							: `box-shadow: inset 0 0 0 2px var(--button-cta-border-color);`
					}
                }
                .card:focus:not(:active) #${id}:not(:active) .button-content${overrideClassSelector}:not(:active),
                .card:focus-visible:not(:active) #${id}:not(:active) .button-content${overrideClassSelector}:not(:active),
                #${id}:focus:not(:active) .button-content${overrideClassSelector}:not(:active),
                #${id}:focus-visible:not(:active) .button-content${overrideClassSelector}:not(:active) {
                    ${addOverride('--button-cta-outline-color', override_button_focus_border || `var(--button-cta-border-color-default)`)}
                    ${
						!useBorderFocus && !isHoverBlock && isGradientButton(bcgFocus)
							? 'box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);'
							: `box-shadow: inset 0 0 0 2px var(--button-cta-border-color);`
					}
                }
                #${id} .button-content svg,
                #${id} .button-content img svg,
                #${id} .button-content svg path,
                #${id} .button-content img svg path {
                    stroke: var(--button-cta-text-color, var(--button-cta-text-color-default)) !important;
                }
                #${id} .button-content svg.play-icon-svg {
                    fill: var(--button-cta-text-color, var(--button-cta-text-color-default)) !important;
                    transform: scale(.7);
                }
            </style>
            <button
                id="${id}"
                role="button"
                onmouseup="this.blur();"
                onclick="this.blur();"
                tabindex="${tabIndex}"
                ${!text ? `aria-label="Clickable button"` : ''}
                class="ark-ui-atom-button-calltoaction-button ${styleModClass}"
                style="${isHoverBlock && isGradientButton(bcgNormal) ? 'pointer-events: initial !important;' : ''}"
            >
                <p 
                    class="button-content ${sizeModClass} text-18 padding-8-top padding-8-bottom${horPaddingsModClass}${passedClass}${overrideClassName}"
                    style="${isHoverBlock && isGradientButton(bcgNormal) ? 'pointer-events: initial !important;' : ''}"
                >
                    ${isIconBefore ? icon : ''}
                    ${!text ? '<slot></slot>' : text}
                    ${!isIconBefore ? icon : ''}
                </p>
            </button>
        `;
}
