import { createEffect, createEvent, createStore, sample, scopeBind } from 'effector';
import { fetchData, geoServiceOptions } from '@/libs/api/data-fetcher';
import { LocalStorageService, LSKeys } from '@/libs/utils/local-storage';
import { $arenaDataStore, $isMicrosoftDomain } from '@/app.model';
import { externalScriptLoaded, externalScriptsUpdated } from '@/features/external-scripts/model';
import { $isCmpAndAdsDisabledByRegion } from '../ad/model';
import { logger } from '@/libs/utils/logger';
import { CUSTOM_BEHAVIOUR_REGIONS } from '@/root/libs/enums/CustomBehaviorRegions';
import { GDPR_REGIONS, NO_CMP_AND_ADS_REGIONS, US_REGIONS, COUNTRY_CODE_TAG_NAME } from '@/libs/utils/constants';
import { CMPData } from '@/root/types/CMPData';
import { browserTrackingConsent } from '@/libs/utils/utils';
import { TExternalScriptRaw } from '@/libs/api/arena-data';
import { setTag } from '@sentry/nextjs';

type TMicrosoft1DSConsentStructure = {
	Required: boolean;
	Analytics: boolean;
	SocialMedia: boolean;
	Advertising: boolean;
};
type TMicrosoft1DSGPCDataSharing = boolean | null;

interface GeoIpResponse {
	ip: string;
	country_code: string;
	country_name: string;
	region_code: string;
	region_name: string;
	city: string;
	zip_code: string;
	time_zone: string;
	latitude: number;
	longitude: number;
	metro_code: number;
}

function getLastCMPConsent(key: LSKeys): boolean | null {
	const item = LocalStorageService.getItem(key, true);
	return item.length > 0 ? item === 'true' : null;
}

const removeMUIDCookieFx = createEffect<void, void>(() => {
	document.cookie = 'MUID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.msn.com; secure; samesite=none';
});
export const fetchUserGeoData = async (): Promise<GeoIpResponse> =>
	await fetchData(process.env.GEO_SERVICE_URL, geoServiceOptions);

export const $userCountry = createStore<string | null>(null);
export const fetchUserCountryFx = createEffect<void, GeoIpResponse>(fetchUserGeoData);
sample({
	clock: fetchUserCountryFx.doneData,
	filter: (data) => !!data,
	fn: (data: GeoIpResponse) => {
		setTag(COUNTRY_CODE_TAG_NAME, data.country_code);
		return data.country_code;
	},
	target: $userCountry,
});

export const getBrowserTrackingConsentFx = createEffect<void, boolean>(browserTrackingConsent);
export const $browserTrackingConsent = createStore<boolean | null>(null).on(
	getBrowserTrackingConsentFx.doneData,
	(_, data) => data,
);

export const $targetingConsentStore = createStore<boolean | null>(null);
export const targetingConsentUpdated = createEvent<boolean | null>();
export const $gdprConsentStore = createStore<boolean | null>(null);
export const gdprConsentUpdated = createEvent<boolean | null>();
export const consentStoresInitFx = createEffect(() => {
	const lsTargetingConsent = LocalStorageService.getItem(LSKeys.targetingConsent, true);
	const lsGdprConsent = LocalStorageService.getItem(LSKeys.gdprCookieConsent, true);
	const INIT_TARGETING_CONSENT = lsTargetingConsent === 'true';
	const INIT_GDPR_CONSENT = lsGdprConsent === 'true';
	return {
		targetingConsent: INIT_TARGETING_CONSENT,
		gdprConsent: INIT_GDPR_CONSENT,
	};
});
sample({
	clock: consentStoresInitFx.doneData,
	fn: ({ targetingConsent }) => targetingConsent,
	target: targetingConsentUpdated,
});
sample({
	clock: consentStoresInitFx.doneData,
	fn: ({ gdprConsent }) => gdprConsent,
	target: gdprConsentUpdated,
});

export const $isGDPRApplies = createStore<boolean | null>(null);
export const isGDPRAppliesUpdated = createEvent<boolean>();
export const $msInitialConsent = createStore<boolean | null>(null);
export const msInitialConsentUpdated = createEvent<{
	thirdPartyAdsOptOutCookie: string | null;
	isMicrosoft: boolean;
}>();

export const $isUS = createStore<boolean | null>(null).on(fetchUserCountryFx.doneData, (_, data) => {
	const isUS = US_REGIONS.has(data.country_code as CUSTOM_BEHAVIOUR_REGIONS);
	return isUS;
});

sample({
	clock: fetchUserCountryFx.doneData,
	fn: ({ country_code }) => {
		const isGDPRApplies = GDPR_REGIONS.has(country_code as CUSTOM_BEHAVIOUR_REGIONS);
		// used in travel case gdpr to non-gdpr
		if (!isGDPRApplies && LocalStorageService.getItem(LSKeys.popupGDPRCookieConsent, true).length > 0) {
			LocalStorageService.setItem(LSKeys.isNonGDPR, true, true);
		}
		return isGDPRApplies;
	},
	target: isGDPRAppliesUpdated,
});
sample({
	clock: isGDPRAppliesUpdated,
	target: $isGDPRApplies,
});

//check for 3PAdsOptOut on Microsoft domains
sample({
	clock: msInitialConsentUpdated,
	source: $msInitialConsent,
	filter: (_, { isMicrosoft }) => isMicrosoft,
	fn: (_, { thirdPartyAdsOptOutCookie }) => thirdPartyAdsOptOutCookie !== '1',
	target: $msInitialConsent,
});

//flow for MS
sample({
	clock: fetchUserCountryFx.doneData,
	source: {
		$isMicrosoftDomain,
		$msInitialConsent,
		$targetingConsentStore,
		$browserTrackingConsent,
		$isGDPRApplies,
	},
	filter: ({ $isMicrosoftDomain }) => $isMicrosoftDomain,
	fn: ({ $msInitialConsent, $targetingConsentStore, $browserTrackingConsent, $isGDPRApplies }, { country_code }) => {
		if (
			$browserTrackingConsent === false ||
			$msInitialConsent === false ||
			NO_CMP_AND_ADS_REGIONS.has(country_code as CUSTOM_BEHAVIOUR_REGIONS)
		) {
			return false;
		}

		const lastTargetingConsent = getLastCMPConsent(LSKeys.popupTargetingConsent);
		if (lastTargetingConsent !== null) {
			return lastTargetingConsent;
		}

		if (!$isGDPRApplies) {
			return true;
		}

		return $targetingConsentStore ?? true;
	},
	target: [targetingConsentUpdated, gdprConsentUpdated],
});

sample({
	clock: $isCmpAndAdsDisabledByRegion,
	source: { $browserTrackingConsent, $targetingConsentStore, $isMicrosoftDomain, $isGDPRApplies },
	filter: ({ $isMicrosoftDomain }) => !$isMicrosoftDomain,
	fn: ({ $browserTrackingConsent, $targetingConsentStore, $isGDPRApplies }, isCmpAndAdsDisabledByRegion) => {
		if ($browserTrackingConsent === false || isCmpAndAdsDisabledByRegion === true) {
			return false;
		}

		const lastTargetingConsent = getLastCMPConsent(LSKeys.popupTargetingConsent);
		if (lastTargetingConsent !== null) {
			return lastTargetingConsent;
		}

		if (!$isGDPRApplies) {
			return true;
		}

		return $targetingConsentStore;
	},
	target: targetingConsentUpdated,
});

sample({
	clock: $isCmpAndAdsDisabledByRegion,
	source: { $browserTrackingConsent, $gdprConsentStore, $isMicrosoftDomain, $isGDPRApplies },
	filter: ({ $isMicrosoftDomain }) => !$isMicrosoftDomain,
	fn: ({ $browserTrackingConsent, $gdprConsentStore, $isGDPRApplies }, isCmpAndAdsDisabledByRegion) => {
		if ($browserTrackingConsent === false || isCmpAndAdsDisabledByRegion === true) {
			return false;
		}
		const lastGDPRConsent = getLastCMPConsent(LSKeys.popupGDPRCookieConsent);
		if (lastGDPRConsent !== null) {
			return lastGDPRConsent;
		}

		if (!$isGDPRApplies) {
			return true;
		}

		return $gdprConsentStore;
	},
	target: gdprConsentUpdated,
});

// load quantcast only if gdpr applies OR user interacted with gdpr
sample({
	clock: $isGDPRApplies,
	source: { $arenaDataStore },
	filter: ({ $arenaDataStore }, _) => Boolean($arenaDataStore.external_scripts?.length),
	fn: ({ $arenaDataStore }, isGDPRApplies) => {
		return isGDPRApplies === true || getLastCMPConsent(LSKeys.popupTargetingConsent) !== null
			? $arenaDataStore.external_scripts
			: ($arenaDataStore.external_scripts as TExternalScriptRaw[]).filter(
					(s) => !s.name.toLowerCase().includes('quantcast'),
				);
	},
	target: externalScriptsUpdated,
});

sample({
	clock: fetchUserCountryFx.doneData,
	fn: ({ country_code }) => {
		return NO_CMP_AND_ADS_REGIONS.has(country_code as CUSTOM_BEHAVIOUR_REGIONS);
	},
	target: $isCmpAndAdsDisabledByRegion,
});
export const $readyToLoadMicrosoft1DS = createStore<boolean>(false);
export const gdprCmpUpdated = createEvent<CMPData>();
export const gdprCmpStartedFx = createEffect(() => {
	const gdprCmpUpdatedBound = scopeBind(gdprCmpUpdated, { safe: true });
	const pollInterval = setInterval(() => {
		if (window.__tcfapi) {
			clearInterval(pollInterval);
			window.__tcfapi('addEventListener', 2, gdprCmpUpdatedBound);
		}
	}, 100);
});

// listen to tcdata events if user is gdpr or had interacted with gdpr before
sample({
	clock: externalScriptLoaded,
	source: {
		$isGDPRApplies,
	},
	filter: ({ $isGDPRApplies }, _) => {
		return $isGDPRApplies === true || getLastCMPConsent(LSKeys.popupTargetingConsent) !== null;
	},
	target: gdprCmpStartedFx,
});

//US, China, non-GDPR regions flow for 1DS
sample({
	clock: fetchUserCountryFx.doneData,
	source: {
		$isUS,
		$isGDPRApplies,
		$isCmpAndAdsDisabledByRegion,
		$isMicrosoftDomain,
		$browserTrackingConsent,
		$msInitialConsent,
	},
	filter: ({ $isMicrosoftDomain }) => $isMicrosoftDomain,
	fn: ({ $isUS, $isGDPRApplies, $isCmpAndAdsDisabledByRegion, $browserTrackingConsent, $msInitialConsent }) => {
		if ($isCmpAndAdsDisabledByRegion) {
			const userConsentDetails: TMicrosoft1DSConsentStructure = {
				Required: true,
				Analytics: false,
				SocialMedia: false,
				Advertising: false,
			};

			LocalStorageService.setItem(LSKeys.userConsentDetails, JSON.stringify(userConsentDetails), true);
			const gpcDataSharingOptIn: TMicrosoft1DSGPCDataSharing = null; //null for China
			LocalStorageService.setItem(LSKeys.gpcDataSharingOptIn, gpcDataSharingOptIn, true);
			window.__app_insights_1ds_key = process.env.APP_INSIGHTS_1DS_KEY;
			return true;
		}
		if ($isUS || !$isGDPRApplies) {
			const userConsentDetails: TMicrosoft1DSConsentStructure = {
				Required: true,
				Analytics: true,
				SocialMedia: true,
				Advertising: true,
			};

			LocalStorageService.setItem(LSKeys.userConsentDetails, JSON.stringify(userConsentDetails), true);
			const gpcDataSharingOptIn: TMicrosoft1DSGPCDataSharing =
				$browserTrackingConsent === true && $msInitialConsent === true;
			LocalStorageService.setItem(LSKeys.gpcDataSharingOptIn, gpcDataSharingOptIn, true);
			window.__app_insights_1ds_key = process.env.APP_INSIGHTS_1DS_KEY;
			return true;
		}
		return false;
	},
	target: $readyToLoadMicrosoft1DS,
});

//separate GDPR flow for 1DS
sample({
	clock: gdprCmpUpdated,
	source: { $browserTrackingConsent, $isMicrosoftDomain, $msInitialConsent, $readyToLoadMicrosoft1DS },
	filter: ({ $isMicrosoftDomain, $readyToLoadMicrosoft1DS }, tcData) =>
		$isMicrosoftDomain && tcData.cmpStatus === 'loaded' && $readyToLoadMicrosoft1DS === false,
	fn: ({ $browserTrackingConsent, $msInitialConsent }, tcData) => {
		const userConsentDetails: TMicrosoft1DSConsentStructure = {
			Required: true,
			Analytics: (tcData.purpose.consents[10] as boolean) || false,
			SocialMedia: (tcData.purpose.consents[7] as boolean) || false,
			Advertising: (tcData.purpose.consents[1] as boolean) || false,
		};
		LocalStorageService.setItem(LSKeys.userConsentDetails, JSON.stringify(userConsentDetails), true);

		const gpcDataSharingOptIn: TMicrosoft1DSGPCDataSharing =
			$browserTrackingConsent === true && $msInitialConsent === true;
		LocalStorageService.setItem(LSKeys.gpcDataSharingOptIn, gpcDataSharingOptIn, true);
		window.__app_insights_1ds_key = process.env.APP_INSIGHTS_1DS_KEY;
		return true;
	},
	target: $readyToLoadMicrosoft1DS,
});
sample({
	clock: gdprCmpUpdated,
	source: { $isCmpAndAdsDisabledByRegion, $browserTrackingConsent, $msInitialConsent },
	filter: (_, tcData) => tcData.eventStatus === 'useractioncomplete',
	fn: ({ $isCmpAndAdsDisabledByRegion, $browserTrackingConsent, $msInitialConsent }, tcData) => {
		const targetingConsent = Boolean(tcData?.purpose?.consents?.[1]);
		LocalStorageService.setItem(LSKeys.popupTargetingConsent, targetingConsent, true);
		if ($msInitialConsent === false || $isCmpAndAdsDisabledByRegion === true || $browserTrackingConsent === false) {
			return false;
		}
		return targetingConsent;
	},
	target: targetingConsentUpdated,
});

sample({
	clock: gdprCmpUpdated,
	source: { $isCmpAndAdsDisabledByRegion, $browserTrackingConsent, $msInitialConsent },
	filter: (_, tcData) => tcData.eventStatus === 'useractioncomplete',
	fn: ({ $isCmpAndAdsDisabledByRegion, $browserTrackingConsent, $msInitialConsent }, tcData) => {
		const gdprConsent = Boolean(tcData?.purpose?.consents?.[1]) && Boolean(tcData?.vendor?.consents?.[11]);
		LocalStorageService.setItem(LSKeys.popupGDPRCookieConsent, gdprConsent, true);
		if ($msInitialConsent === false || $isCmpAndAdsDisabledByRegion === true || $browserTrackingConsent === false) {
			return false;
		}
		return gdprConsent;
	},
	target: gdprConsentUpdated,
});

sample({
	clock: targetingConsentUpdated,
	source: $isGDPRApplies,
	filter: (_, consent) => consent !== null,
	fn: (isGDPRApplies, targetingConsent) => {
		logger.debug(`gdprApplies = ${isGDPRApplies}, targetingConsent = ${targetingConsent}`);
		LocalStorageService.setItem(LSKeys.targetingConsent, targetingConsent, true);
		return targetingConsent;
	},
	target: $targetingConsentStore,
});

sample({
	clock: gdprConsentUpdated,
	source: $isGDPRApplies,
	filter: (_, consent) => consent !== null,
	fn: (isGDPRApplies, gdprConsent) => {
		logger.debug(`gdprApplies = ${isGDPRApplies}, gdprConsent = ${gdprConsent}`);
		LocalStorageService.setItem(LSKeys.gdprCookieConsent, gdprConsent, true);
		return gdprConsent;
	},
	target: $gdprConsentStore,
});

sample({
	clock: gdprConsentUpdated,
	filter: (consent) => consent === false,
	target: removeMUIDCookieFx,
});

export const openGDPRCmpPopupFx = createEffect(() => {
	if (window.__tcfapi) {
		window.__tcfapi('displayConsentUi', 2, function () {});
	}
});
export const openUSPCmpPopupFx = createEffect(() => {
	if (window.__uspapi) {
		window.__uspapi('displayUspUi');
	}
});

sample({
	clock: gdprCmpUpdated,
	source: { $isGDPRApplies },
	filter: (_, tcData) => tcData.eventStatus === 'tcloaded',
	fn: ({ $isGDPRApplies }, tcData) => {
		// display CMP popup again for gdpr who travelled to non-gdpr and returned to gdpr
		if ($isGDPRApplies && LocalStorageService.getItem(LSKeys.isNonGDPR, true)) {
			LocalStorageService.removeItem(LSKeys.isNonGDPR, true);
			if (window.__tcfapi) {
				window.__tcfapi('displayConsentUi', 2, function () {});
			}
		}
	},
});
