export function atomThumbnailIconMarkup(src: string, className?: string, alt?: string, width?: number, height?: number, marginStyle?: string, isThumbnail?: boolean) {
  if (!isThumbnail) return '';
  const altStr = alt || 'thumbnail icon';
  const widthStr = width ? ` width="${width}"` : '';
  const heightStr = height ? ` height="${height}"` : '';
  const noImg = !src || src === '' ? ' noImg' : '';
  const classStr = className ? ` class="thumbnail-icon ${className + noImg}"` : `thumbnail-icon ${noImg}`;
  const marginStyleStr = marginStyle ? ` style="${marginStyle}"` : '';

  // if src is type svg, use eager loading
  const srcType: string | undefined = src.split('.').pop();
  const srcTypeArr = ['svg'];
  const srcTypeIsSvg = srcTypeArr.includes(srcType || '');
  const loadingTypeStr = srcTypeIsSvg ? 'eager' : 'lazy';
  return src ? `<img alt="${altStr}" src="${src}" loading="${loadingTypeStr}" ${widthStr}${heightStr}${classStr}${marginStyleStr}/>` : `<div ${classStr}></div>`;
}