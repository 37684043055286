import { createEffect, createEvent, createStore, sample } from 'effector';
import { loadScript } from '@/libs/utils/script-load';
import { CMSComponentProperties, ComponentsNames } from '@/types/types';
import { ArenaDataState } from '@/libs/api/arena-data';
import { $arenaDataStore, setCurrentPage } from '@/app.model';
import { $gameData } from '@/root/app/[locale]/games/[slug]/model';
import { GameModelFromFeed } from '@/games';

export enum AdTypes {
	AD_160x600 = '160,600',
	AD_300x250 = '300,250',
	AD_300x600 = '300,600',
	AD_728x90 = '728,90',
	AD_768x90 = '768,90',
	AD_970x90 = '970,90',
	AD_970x250 = '970,250',
	AD_320x50 = '320,50',
}

export type AdProps = {
	adOptions: AdOptions;
	className?: string;
};

export type AdDimensionType = `${number},${number}`;
export type AdOptions = {
	'__component'?: string;
	'product': string;
	'dimensions': AdTypes | string; //stringified Array<[number, number]>
	'data-id': string;
	'data-testid'?: string;
	'id'?: string;
};
export interface TAdComponent extends CMSComponentProperties {
	__component: `web-components.${ComponentsNames.AdComponent}`;
	ad_id: string | null;
	dimensions: Array<AdDimensionType> | null;
}

export const getDimensions = (dimensions: Array<AdDimensionType> | null): AdOptions['dimensions'] | null => {
	if (!dimensions) {
		return null;
	}
	return JSON.stringify(
		dimensions.map((dimension) => {
			const [width, height] = dimension.split(',');
			return [Number(width), Number(height)];
		}),
	);
};
export const buildTheySellLibPath = (libPath: string, theySellName: string): string => {
	const url = new URL(libPath);
	const parts = url.pathname.split('/');

	parts.splice(parts.length - 1, 0, theySellName);
	url.pathname = parts.join('/');

	return url.href;
};

const initDisplayAdScript = (theySellAd: ArenaDataState['theySellAd']) => {
	let url = process.env.DISPLAY_ADS;
	if (theySellAd) {
		url = buildTheySellLibPath(url, theySellAd.displayAdUrl);
	}
	loadScript(url);
};
export const displayAdStartedFx = createEffect(initDisplayAdScript);
export const $displayAdStarted = createStore(false).on(displayAdStartedFx.done, () => true);

export const setAdsMetadata = createEffect(({ $gameData }: { $gameData: GameModelFromFeed | null }) => {
	window.__gameData = $gameData;
});

sample({
	clock: setCurrentPage,
	source: { $gameData },
	target: setAdsMetadata,
});

export type TDisplayAdsEmpty = {
	[key: AdOptions['data-id']]: boolean;
};
export const $displayAdsEmpty = createStore<TDisplayAdsEmpty>({});
export const displayAdEmptyUpdated = createEvent<{
	id: AdOptions['data-id'];
	empty: boolean;
}>();
export const displayAdEmptyUnmounted = createEvent<AdOptions['data-id']>();
export const $isCmpAndAdsDisabledByRegion = createStore<boolean | null>(null);
sample({
	clock: displayAdEmptyUpdated,
	source: $displayAdsEmpty,
	fn: (state, { id, empty }) => ({ ...state, [id]: empty }),
	target: $displayAdsEmpty,
});
sample({
	clock: displayAdEmptyUnmounted,
	source: $displayAdsEmpty,
	fn: (state, id) => {
		const newState = { ...state };
		delete newState[id];
		return newState;
	},
	target: $displayAdsEmpty,
});

sample({
	clock: $isCmpAndAdsDisabledByRegion,
	source: { $displayAdStarted, $arenaDataStore },
	filter: ({ $displayAdStarted }, isAdsDisabled) => {
		return Boolean(isAdsDisabled !== null && !isAdsDisabled && !$displayAdStarted);
	},
	fn: ({ $arenaDataStore: arenaData }) => {
		return arenaData.theySellAd;
	},
	target: displayAdStartedFx,
});
