/* eslint-disable @next/next/no-img-element */
'use client';

// Tracking pixel
import React from 'react';
type TPixelProps = {
  id: string;
  src: string;
};
const Pixel = ({
  id,
  src
}: TPixelProps) => {
  return <img id={id} src={src} alt='tracking-pixel' aria-hidden='true' style={{
    display: 'none'
  }} data-sentry-component="Pixel" data-sentry-source-file="index.tsx" />;
};
export default Pixel;