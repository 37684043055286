'use client';

import Image, { ImageProps } from 'next/image';
import { SyntheticEvent, useState } from 'react';
import { useUnit } from 'effector-react';
import { $domainStore } from '@/app.model';
export const StaticImage = (props: ImageProps) => {
  const domain = useUnit($domainStore) as string;
  const [isError, setIsError] = useState(false);
  const [fallbackSrc, setFallbackSrc] = useState(props.src as string);
  const onError = (event: SyntheticEvent<HTMLImageElement>) => {
    if (!isError) {
      const absoluteUrl = `${domain}${props.src}`;
      setFallbackSrc(absoluteUrl);
    }
    setIsError(true);
  };
  return <Image {...props} alt={props.alt} src={fallbackSrc} onError={onError} data-sentry-element="Image" data-sentry-component="StaticImage" data-sentry-source-file="index.tsx" />;
};