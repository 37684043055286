import React from 'react';
import styles from './styles.module.css';
interface CloseIconProps {
  className?: string;
}
const CloseIcon: React.FC<CloseIconProps> = ({
  className
}) => {
  return <svg className={`${styles.closeIconSvg} ${className || ''}`} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-component="CloseIcon" data-sentry-source-file="CloseIcon.tsx">
			<path d='M14 2L2 14M2 2L14 14' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' data-sentry-element="path" data-sentry-source-file="CloseIcon.tsx" />
		</svg>;
};
export default CloseIcon;