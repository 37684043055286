'use client';
import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import {
	$gdprConsentStore,
	$isGDPRApplies,
	$targetingConsentStore,
	consentStoresInitFx,
	msInitialConsentUpdated,
} from '@/features/analytics/model';
import { $isMicrosoftDomain } from '@/app.model';
import { aiAnalyticsStartedFx } from '@/features/analytics/AI/model';
import { gaAnalyticsStartedFx } from '@/features/analytics/GA/model';
import { getBrowserCookie } from '@/libs/utils/utils';
import { THIRD_PARTY_ADS_OPT_OUT_COOKIE_NAME } from '@/libs/utils/constants';

export const useCMPInit = () => {
	const initConsentStores = useUnit(consentStoresInitFx);
	const gdprConsent = useUnit($gdprConsentStore);
	const targetingConsent = useUnit($targetingConsentStore);
	const isGDPRApplies = useUnit($isGDPRApplies);
	const isMicrosoft = useUnit($isMicrosoftDomain);
	const initAI = useUnit(aiAnalyticsStartedFx);
	const initGA = useUnit(gaAnalyticsStartedFx);
	const updateMsInitialConsent = useUnit(msInitialConsentUpdated);

	useEffect(() => {
		const thirdPartyAdsOptOutCookie = getBrowserCookie(THIRD_PARTY_ADS_OPT_OUT_COOKIE_NAME) ?? null;
		updateMsInitialConsent({ isMicrosoft, thirdPartyAdsOptOutCookie });
	}, [isMicrosoft, updateMsInitialConsent]);

	useEffect(() => {
		initConsentStores();
	}, [initConsentStores]);

	useEffect(() => {
		if (gdprConsent) {
			initAI();
		}
	}, [gdprConsent, initAI]);

	useEffect(() => {
		//init GA for non-gdpr users
		if (isGDPRApplies === false && targetingConsent) {
			initGA();
		}
	}, [initGA, isGDPRApplies, targetingConsent]);
};
